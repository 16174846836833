<template>
  <div :class="`hepato ${getCurrentLangs.lang}`" data-view>
    <Header />
    <scrollAnalytics></scrollAnalytics>

    <section class="top">
      <div class="top__head-wrapper">
        <div class="top__head" v-html="$t('hepato.head')"></div>

        <router-link to="/cases" class="top__back-btn cursor-hover">
          <div class="top__arrow">
            <svg
              viewBox="0 0 57 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.5388 19.3223L14.8514 30.01L25.5484 40.7071"
                stroke="black"
                stroke-linecap="round"
              />
              <path
                d="M15.2988 29.973L38.2398 29.9732"
                stroke="black"
                stroke-linecap="round"
              />
            </svg>
          </div>

          {{ $t("back-btn") }}
        </router-link>
      </div>

      <h1 class="top__title animation iosTitle type1">
        <span
          class="text-wrapper"
          v-for="(key, value) in $t('hepato.title')"
          :key="value"
        >
          <span class="letters" v-html="key"></span>
        </span>
      </h1>
    </section>

    <section class="banner">
      <picture>
        <source
          srcset="@/assets/img/cases/hepato/banner-mob.png"
          media="(max-width: 425px)"
        />
        <source
          srcset="@/assets/img/cases/hepato/banner-tablet.png"
          media="(max-width: 768px)"
        />
        <img
          v-parallax.modifier.absY="0.1"
          src="@/assets/img/cases/hepato/banner.png"
          alt=""
        />
      </picture>
    </section>

    <section class="overview">
      <div class="overview__double-block">
        <div class="overview__left">
          <p
            class="overview__left-text"
            v-html="$t('hepato.overview.left-text')"
          ></p>
        </div>
        <div class="overview__right">
          <p
            class="overview__right-text"
            v-html="$t('hepato.overview.right-text')"
          ></p>
        </div>
      </div>
    </section>

    <section class="challenge">
      <div class="challenge__double-block">
        <div class="challenge__left">
          <p
            class="challenge__left-text"
            v-html="$t('hepato.challenge.left-text')"
          ></p>
        </div>
        <div class="challenge__right">
          <p
            class="challenge__right-title"
            v-html="$t('hepato.challenge.right-title')"
          ></p>
          <p
            class="challenge__right-text"
            v-html="$t('hepato.challenge.right-text')"
          ></p>
        </div>
      </div>

      <picture class="challenge__image">
        <source
          srcset="@/assets/img/cases/hepato/challenge-mob.png"
          media="(max-width: 425px)"
        />
        <source
          srcset="@/assets/img/cases/hepato/challenge-tablet.png"
          media="(max-width: 768px)"
        />
        <img
          src="@/assets/img/cases/hepato/challenge.png"
          alt="challenge-image"
        />
      </picture>
    </section>

    <section class="idea">
      <div class="idea__double-block">
        <div class="idea__left">
          <p class="idea__left-text" v-html="$t('hepato.idea.left-text')"></p>
        </div>
        <div class="idea__right">
          <p class="idea__right-text" v-html="$t('hepato.idea.right-text')"></p>
          <p
            class="idea__right-title"
            v-html="$t('hepato.idea.right-title')"
          ></p>
        </div>
      </div>

      <picture class="idea__image">
        <source
          srcset="@/assets/img/cases/hepato/idea-mob.png"
          media="(max-width: 360px)"
        />
        <source
          srcset="@/assets/img/cases/hepato/idea-tablet.png"
          media="(max-width: 768px)"
        />
        <img
          v-parallax.modifier.absY="0.02"
          src="@/assets/img/cases/hepato/idea.png"
          alt="idea-image"
        />
      </picture>
    </section>

    <section class="execution">
      <div class="execution__double-block">
        <div class="execution__left">
          <p
            class="execution__left-text"
            v-html="$t('hepato.execution.left-text')"
          ></p>
        </div>
        <div class="execution__right">
          <p
            class="execution__right-text"
            v-html="$t('hepato.execution.right-text')"
          ></p>
          <p
            class="execution__right-title"
            v-html="$t('hepato.execution.right-title')"
          ></p>
          <ul class="execution__right-list">
            <li
              class="execution__right-item"
              v-for="(item, idx) in $t('hepato.execution.right-list')"
              :key="`right-item-${idx + 1}`"
            >
              <span v-html="item"></span>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section class="videos">
      <div class="videos__overflow">
        <swiper
          ref="videosSlider"
          :options="swiperVideosOptions"
          class="videos__slider"
          @slideChange="slideChange"
        >
          <swiper-slide
            v-for="(val, index) in 3"
            :key="index"
            class="videos__slider--slide"
          >
            <div class="videos__video-wrapper cursor-hover">
              <div
                class="videos__play-btn cursor-hover"
                @click="playSwiperVideos(`videos-${val}`)"
                v-bind:class="[{ active: videosTrigger !== `videos-${val}` }]"
              ></div>

              <video
                v-if="getCurrentLangs.lang === 'en'"
                @click="playSwiperVideos(`videos-${val}`)"
                :ref="`videos-${val}`"
                :poster="require(`@/assets/img/cases/hepato/poster-${val}.jpg`)"
              >
                <source
                  :src="require(`@/assets/video/hepato/hepato-${val}-en.mp4`)"
                  type="video/mp4"
                />
              </video>
              <video
                v-else
                @click="playSwiperVideos(`videos-${val}`)"
                :ref="`videos-${val}`"
                :poster="require(`@/assets/img/cases/hepato/poster-${val}.jpg`)"
              >
                <source
                  :src="require(`@/assets/video/hepato/hepato-${val}-ua.mp4`)"
                  type="video/mp4"
                />
              </video>
            </div>
          </swiper-slide>

          <div class="swiper-videos-pagination" slot="pagination"></div>
        </swiper>
        <div class="pagination__wrapper">
          <svg
            class="
              left
              swiper-videos-button swiper-videos-button-prev
              cursor-hover
            "
            width="58"
            height="58"
            viewBox="0 0 58 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.2539 19.7256L15.5665 30.4133L26.2635 41.1104"
              stroke-linecap="round"
            />
            <path d="M16.016 30.3763L38.957 30.3765" stroke-linecap="round" />
          </svg>
          <svg
            class="
              right
              swiper-videos-button swiper-videos-button-next
              cursor-hover
            "
            width="58"
            height="58"
            viewBox="0 0 58 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.7461 19.7256L42.4335 30.4133L31.7365 41.1104"
              stroke-linecap="round"
            />
            <path d="M41.984 30.3763L19.043 30.3765" stroke-linecap="round" />
          </svg>
        </div>
      </div>
    </section>

    <section class="images">
      <swiper
        ref="imagesSlider"
        :options="swiperImagesOptions"
        class="images__slider"
      >
        <swiper-slide
          v-for="(val, index) in 5"
          :key="index"
          class="images__slider--slide"
        >
          <img
            class="images__img"
            :src="require(`@/assets/img/cases/hepato/swiper-img-${val}.png`)"
            :alt="`swiper-img-${val}`"
          />
        </swiper-slide>
        <div class="swiper-images-pagination" slot="pagination"></div>
      </swiper>
      <div class="pagination__wrapper">
        <svg
          class="
            left
            swiper-images-button swiper-images-button-prev
            cursor-hover
          "
          width="58"
          height="58"
          viewBox="0 0 58 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.2539 19.7256L15.5665 30.4133L26.2635 41.1104"
            stroke-linecap="round"
          />
          <path d="M16.016 30.3763L38.957 30.3765" stroke-linecap="round" />
        </svg>
        <svg
          class="
            right
            swiper-images-button swiper-images-button-next
            cursor-hover
          "
          width="58"
          height="58"
          viewBox="0 0 58 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.7461 19.7256L42.4335 30.4133L31.7365 41.1104"
            stroke-linecap="round"
          />
          <path d="M41.984 30.3763L19.043 30.3765" stroke-linecap="round" />
        </svg>
      </div>
    </section>

    <div class="photozone-bg">
      <section class="photozone">
        <div class="photozone__double-block">
          <p
            class="photozone__left-text"
            v-html="$t('hepato.photozone.left-text')"
          ></p>
        </div>
        <div class="photozone__img-wrapper">
          <div
            class="photozone__img-col photozone__img-col--left"
            v-parallax.modifier.absY="-0.02"
          >
            <div class="photozone__img-col-left">
              <img
                class="photozone__image"
                src="@/assets/img/cases/hepato/photozone-1.png"
                alt="photozone-1"
              />
            </div>
            <div class="photozone__img-col-left">
              <img
                class="photozone__image"
                src="@/assets/img/cases/hepato/photozone-2.png"
                alt="photozone-2"
              />
            </div>
          </div>
          <div
            class="photozone__img-col photozone__img-col--right"
            v-parallax.modifier.absY="0.02"
          >
            <img
              class="photozone__image"
              src="@/assets/img/cases/hepato/photozone-3.png"
              alt="photozone-3"
            />
          </div>
        </div>
      </section>
    </div>

    <section class="results">
      <div class="results__double-block">
        <div class="results__left">
          <p
            class="results__left-text"
            v-html="$t('hepato.results.left-text')"
          ></p>
        </div>
        <div class="results__right">
          <p
            class="results__right-title"
            v-html="$t('hepato.results.right-title')"
          ></p>
          <p
            class="results__right-text"
            v-html="$t('hepato.results.right-text')"
          ></p>
        </div>
      </div>
    </section>

    <similar :dataSlider="similarData"></similar>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import similar from "@/components/similar.vue";
import Footer from "@/components/NewFooter.vue";
import anime from "animejs";
import { mapGetters, mapMutations } from "vuex";
// import getElementVisible from '@/helper';

export default {
  name: "Hepato",
  components: {
    Header,
    Footer,
    similar,
  },

  data() {
    return {
      similarData: {
        title: "similar-title-type1",
        prevLink: "/cases/listerine",
        nextLink: "/cases/vitrolla",
        linkShow: true,
        slider: [
          "slider-vitrolya",
          "slider-olfen",
          "slider-shift-work-disorder",
          "slider-vacciland",
        ],
      },
      videosTrigger: null,
      currentSwiperVideo: null,
      activeVideosSlide: 0,
      swiperVideosOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: ".swiper-videos-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".swiper-videos-button-next",
          prevEl: ".swiper-videos-button-prev",
        },
      },
      swiperImagesOptions: {
        slidesPerView: "1.2",
        spaceBetween: 9,
        loop: true,
        breakpoints: {
          426: {
            spaceBetween: 20,
          },
          769: {
            spaceBetween: 29,
          },
        },
        pagination: {
          el: ".swiper-images-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".swiper-images-button-next",
          prevEl: ".swiper-images-button-prev",
        },
      },
    };
  },

  mounted() {
    this.setCurrentPage("cases");

    setTimeout(() => {
      const whySection = document.querySelector(".top");
      const textWrapperAll = whySection.querySelectorAll(
        ".text-wrapper .letters"
      );
      textWrapperAll.forEach((e, index) => {
        const textWrapper = e;

        if (textWrapper.textContent.includes("®")) {
          textWrapper.innerHTML = textWrapper.textContent
            .replace(/\S/g, "<span class='letter'>$&</span>")
            .replace("®", "<sup>®</sup>");
        } else {
          textWrapper.innerHTML = textWrapper.textContent.replace(
            /\S/g,
            "<span class='letter'>$&</span>"
          );
        }

        anime.timeline({ loop: false }).add({
          targets: ".text-wrapper .letter",
          translateY: ["1.2em", 0],
          translateZ: 0,
          duration: 750,
          delay: (el, i) => 50 * i,
        });
      });
    }, 20);
  },

  methods: {
    ...mapMutations(["setCurrentPage"]),

    playSwiperVideos(ref) {
      const swiperVideo = this.$refs[ref][0];

      if (swiperVideo.paused) {
        this.stopSwiperVideos();

        swiperVideo.play();
        this.videosTrigger = ref;
        this.currentSwiperVideo = swiperVideo;
        gtag('event', 'scroll', {'event_category': 'play carousel video: ' + ref, 'event_label': this.$route.path})

      } else {
        swiperVideo.pause();
        this.videosTrigger = null;
        this.currentSwiperVideo = null;
      }
    },
    stopSwiperVideos() {
      if (this.currentSwiperVideo) {
        const swiperVideo = this.$refs[this.videosTrigger][0];
        swiperVideo.currentTime = 0;

        this.currentSwiperVideo.pause();
        this.videosTrigger = null;
        this.currentSwiperVideo = null;
      }
    },
    slideChange() {
      this.stopSwiperVideos();
    },
  },

  computed: {
    ...mapGetters(["getCurrentLangs"]),
    swiper() {
      return this.$refs.videosSlider.$swiper;
    },
  },
};
</script>

<style scoped lang="scss">
.iosActive .hepato {
  &.en {
    .top__title {
      @media screen and (max-width: 1024px) {
        font-size: 80px;

        .text-wrapper:last-child {
          font-size: 0.85em;
        }
      }
    }
  }
  &.ua {
    .top__title {
      @media screen and (max-width: 1024px) {
        font-size: 80px;

        .text-wrapper:last-child {
          font-size: 0.8em;
        }
      }
      @media screen and (max-width: 365px) {
        .text-wrapper:last-child {
          font-size: 0.75em;
        }
      }
    }
  }
}
.hepato {
  margin-top: 74px;

  @media screen and (max-width: 768px) {
    margin-top: 68px;
  }
  @media screen and (max-width: 660px) {
    margin-top: 56px;
  }

  &.ua {
    .top {
      &__title {
        @media screen and (max-width: 1060px) {
          font-size: 90px;
        }
        @media screen and (max-width: 768px) {
          font-size: 56px;
        }
        @media screen and (max-width: 490px) {
          font-size: 40px;
        }
      }
    }
  }

  .top {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 88px 45px 52px 45px;

    @media screen and (max-width: 768px) {
      padding: 36px 45px 40px 45px;
    }
    @media screen and (max-width: 425px) {
      padding: 10px 20px 24px 20px;
    }

    &__head-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 32px;

      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
      @media screen and(max-width: 500px) {
        flex-direction: column-reverse;
      }

      @media screen and (max-width: 425px) {
        margin-bottom: 10px;
      }
    }
    &__back-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;

      letter-spacing: 0.04em;
      min-width: 158px;

      color: var(--text-primary-color-case);

      display: flex;
      align-items: center;

      transition: all 0.2s ease;

      @media screen and (min-width: 1023px) {
        &:hover {
          svg {
            path {
              stroke: #29f6d9;
            }
          }
        }
      }

      &:active {
        svg {
          path {
            stroke: #29f6d9;
          }
        }
      }

      @media screen and(max-width: 768px) {
        margin-top: 0;
      }
      @media screen and(max-width: 500px) {
        margin: 0 0 0 auto;
      }
    }

    &__arrow {
      width: 40px;
      margin-right: 10px;

      svg {
        width: 100%;
        height: auto;

        path {
          transition: all 0.2s ease;
          stroke: var(--text-primary-color-case);
        }

        display: block;
      }
    }

    &__head {
      line-height: 24px;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--case-allegra-color-top);
      text-align: left;
      font-weight: 500;
      font-size: 16px;
      padding: 0 0 0 0.07em;
      margin: 0 auto 0 0;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__title {
      font-family: "TimesNewRoman-I";
      font-size: 108px;
      text-align: left;
      font-weight: 400;

      font-style: italic;

      color: var(--text-primary-color-case);
      width: 100%;
      line-height: 0.6em;

      position: relative;

      .text-wrapper {
        position: relative;
        display: block;
        overflow: hidden;
        padding: 0.1em 0.07em 0.1em 0.07em;

        @media screen and (max-width: 930px) {
          &:last-child {
            font-size: 0.75em;
          }
        }
      }

      ::v-deep .letter {
        display: inline-block;
        line-height: 0.9em;
      }
      @media screen and (max-width: 768px) {
        font-size: 56px;
      }
      @media screen and (max-width: 425px) {
        font-size: 40px;
      }
    }
  }

  .banner {
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  .overview {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 129px 45px 0 45px;

    @media screen and (max-width: 768px) {
      padding: 56px 44px 0 44px;
    }

    @media screen and (max-width: 425px) {
      padding: 54px 20px 0 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &__left {
      margin-right: 40px;
      min-width: 157px;

      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }

    &__left-text {
      font-size: 16px;
      line-height: 1.8em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case);
      font-weight: 500;
      text-align: left;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__right {
      margin-left: 40px;

      max-width: 66%;
      width: 100%;

      @media screen and (max-width: 600px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__right-text {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);
    }
  }
  .challenge {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 57px 45px 0 45px;

    @media screen and (max-width: 768px) {
      padding: 55px 44px 0 44px;
    }

    @media screen and (max-width: 425px) {
      padding: 54px 20px 0 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &__left {
      margin-right: 40px;
      min-width: 157px;

      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }

    &__left-text {
      font-size: 16px;
      line-height: 1.8em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case);
      font-weight: 500;
      text-align: left;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__right {
      margin-left: 40px;

      max-width: 66%;
      width: 100%;

      @media screen and (max-width: 600px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__right-title {
      text-align: left;
      font-size: 24px;
      line-height: 36px;
      color: var(--text-primary-color-case);
      font-weight: 500;
      margin-bottom: 26px;

      @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 21px;
      }
      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 24px;
      }
    }

    &__right-text {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);
    }

    &__image {
      margin: 89px auto 0 auto;
      display: block;

      @media screen and (max-width: 768px) {
        margin-top: 72px;
      }
      @media screen and (max-width: 425px) {
        margin-top: 54px;
      }
    }
  }
  .idea {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 150px 45px 0 45px;

    @media screen and (max-width: 768px) {
      padding: 103px 44px 0 44px;
    }

    @media screen and (max-width: 425px) {
      padding: 75px 20px 0 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &__left {
      margin-right: 40px;
      min-width: 157px;

      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 31px;
      }
    }

    &__left-text {
      font-size: 16px;
      line-height: 1.8em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case);
      font-weight: 500;
      text-align: left;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__right {
      margin-left: 40px;

      max-width: 66%;
      width: 100%;

      @media screen and (max-width: 600px) {
        margin-left: 0;
        max-width: 100%;
      }
    }
    &__right-text {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);
      margin-bottom: 30px;

      @media screen and (max-width: 768px) {
        margin-bottom: 33px;
      }
      @media screen and (max-width: 425px) {
        margin-bottom: 24px;
      }
    }
    &__right-title {
      text-align: left;
      font-size: 24px;
      line-height: 36px;
      color: var(--text-primary-color-case);
      font-weight: 500;

      @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
      }
      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    &__image {
      margin: 89px auto 0 auto;
      display: block;
      overflow: hidden;

      @media screen and (max-width: 768px) {
        margin-top: 72px;
      }
      @media screen and (max-width: 425px) {
        margin-top: 54px;
      }
    }
  }
  .execution {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 120px 45px 0 45px;

    @media screen and (max-width: 768px) {
      padding: 68px 44px 0 44px;
    }

    @media screen and (max-width: 425px) {
      padding: 53px 20px 0 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &__left {
      margin-right: 40px;
      min-width: 157px;

      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 31px;
      }
    }

    &__left-text {
      font-size: 16px;
      line-height: 1.8em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case);
      font-weight: 500;
      text-align: left;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__right {
      margin-left: 40px;

      max-width: 66%;
      width: 100%;

      @media screen and (max-width: 600px) {
        margin-left: 0;
        max-width: 100%;
      }
    }
    &__right-text {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);
      margin-bottom: 37px;

      @media screen and (max-width: 768px) {
        margin-bottom: 23px;
      }
      @media screen and (max-width: 425px) {
        margin-bottom: 31px;
      }
    }
    &__right-title {
      text-align: left;
      font-size: 24px;
      line-height: 36px;
      color: var(--text-primary-color-case);
      font-weight: 500;
      margin-bottom: 49px;

      @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 31px;
      }
      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 34px;
      }
    }

    &__right-item {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);
      margin-bottom: 42px;

      display: flex;
      align-items: flex-start;

      &::before {
        content: "—";
        display: block;
        margin-right: 22px;
        text-align: left;
        font-size: 24px;
        line-height: inherit;
        font-weight: 500;
        color: var(--text-primary-color-case);
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .videos {
    max-width: calc(1110px + 90px);
    margin: 0 auto;
    position: relative;
    padding: 97px 44px 10px 44px;

    @media screen and (max-width: 768px) {
      padding-top: 88px;
    }

    @media screen and (max-width: 425px) {
      padding: 53px 20px 10px 20px;
    }
    &__overflow {
      overflow: hidden;
    }
    ::v-deep .swiper-container {
      overflow: visible;
    }
    ::v-deep .swiper-slide {
      &:not(.swiper-slide-active) {
        opacity: 0.5;
      }
    }

    ::v-deep .swiper-videos-button {
      margin-top: 0;
      position: relative;
      width: auto;
      height: auto;
      color: #000;
      stroke: var(--text-primary-color);

      &:hover,
      &:active {
        &:not(.swiper-button-disabled) {
          stroke: #29f6d9;
        }
      }
    }
    ::v-deep .swiper-button-disabled {
      stroke: var(--service-border-color);
    }
    ::v-deep .swiper-videos-pagination {
      bottom: 0;
      color: #d4dadf;
      position: relative;
      margin-top: 36px;

      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #92a1ae;

      @media screen and (max-width: 425px) {
        margin-top: 26px;
      }
    }

    ::v-deep .swiper-pagination-current {
      font-family: "TimesNewRoman-I";
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      font-style: italic;
      color: var(--text-primary-color);
      letter-spacing: 0.04em;
    }

    .pagination__wrapper {
      position: absolute;
      left: 50%;
      bottom: -6px;
      transform: translateX(-50%);
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .left {
        margin-right: 60px;
      }
    }

    &__video-wrapper {
      position: relative;

      video {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &__play-btn {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: none;

      @media screen and (min-width: 1023px) {
        &:hover {
          &:before {
            transform: scale(1.1);
          }
        }
      }

      &.active {
        display: block;

        &:before {
          opacity: 1;
        }
      }

      &:before {
        content: "Play";
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        opacity: 0;

        justify-content: center;
        align-items: center;
        background: #29f6d9;

        z-index: 2;

        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        margin: auto;

        color: #000000;

        transition: all 0.2s ease;

        font-family: "TimesNewRoman-I";
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.04em;

        @media screen and (max-width: 425px) {
          width: 60px;
          height: 60px;
          font-size: 16px;
        }
      }
    }
  }
  .images {
    max-width: calc(1110px + 90px);
    margin: 0 auto;

    padding: 73px 44px 10px 44px;
    position: relative;

    @media screen and (max-width: 768px) {
      padding: 56px 0 10px 44px;
    }
    @media screen and (max-width: 425px) {
      padding: 11px 0 10px 20px;
    }
    ::v-deep .swiper-slide {
      &:not(.swiper-slide-active) {
        opacity: 0.5;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          content: "";
          display: block;
          background: rgba(196, 196, 196, 0.5);
        }
      }
    }
    ::v-deep .swiper-images-button {
      margin-top: 0;
      position: relative;
      width: auto;
      height: auto;
      color: #000;
      stroke: var(--text-primary-color);

      &:hover,
      &:active {
        &:not(.swiper-button-disabled) {
          stroke: #29f6d9;
        }
      }
    }
    ::v-deep .swiper-button-disabled {
      stroke: var(--service-border-color);
    }
    ::v-deep .swiper-images-pagination {
      bottom: 0;
      color: #d4dadf;
      position: relative;
      margin-top: 36px;

      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #92a1ae;

      @media screen and (max-width: 768px) {
        padding-right: 44px;
      }
      @media screen and (max-width: 425px) {
        padding-right: 20px;
        margin-top: 26px;
      }
    }

    ::v-deep .swiper-pagination-current {
      font-family: "TimesNewRoman-I";
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      font-style: italic;
      color: var(--text-primary-color);
      letter-spacing: 0.04em;
    }

    .pagination__wrapper {
      position: absolute;
      left: 50%;
      bottom: -6px;
      transform: translateX(-50%);
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .left {
        margin-right: 60px;
      }
    }

    &__img {
      display: block;
    }
  }
  .photozone-bg {
    margin-top: 110px;
    background: var(--case-hepato-photozone-bg);

    @media screen and (max-width: 768px) {
      margin-top: 67px;
    }

    @media screen and (max-width: 425px) {
      margin-top: 56px;
    }
  }
  .photozone {
    max-width: calc(1110px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 112px 45px;

    @media screen and (max-width: 768px) {
      padding: 82px 45px 110px 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 34px 19px 71px 19px;
    }

    &__double-block {
      margin-bottom: 100px;

      @media screen and (max-width: 768px) {
        margin-bottom: 62px;
      }
      @media screen and (max-width: 425px) {
        margin-bottom: 39px;
      }
    }

    &__left-text {
      color: var(--text-primary-color-case);

      font-weight: 500;
      font-size: 16px;
      line-height: 1.8em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      text-align: left;

      @media screen and (max-width: 500px) {
        font-size: 12px;
      }
    }
    &__img-wrapper {
      display: flex;

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }
    &__img-col {
      &--left {
        margin-right: 20px;

        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media screen and (max-width: 768px) {
          margin-right: 18px;
        }
        @media screen and (max-width: 600px) {
          order: 1;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          margin-top: 25px;
          margin-right: 0;
        }
      }
      &--right {
        max-width: 720px;
      }
    }
    &__img-col-left {
      &:last-child {
        margin-top: 20px;

        @media screen and (max-width: 768px) {
          max-width: 155px;
          margin-top: 18px;
        }
        @media screen and (max-width: 600px) {
          margin-top: 0;
          margin-left: 18px;
        }
      }
    }
    &__image {
      display: block;
    }
  }
  .results {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 120px 45px 0 45px;

    @media screen and (max-width: 768px) {
      padding: 74px 44px 0 44px;
    }

    @media screen and (max-width: 425px) {
      padding: 71px 20px 0 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &__left {
      margin-right: 40px;
      min-width: 157px;

      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }

    &__left-text {
      font-size: 16px;
      line-height: 1.8em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case);
      font-weight: 500;
      text-align: left;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__right {
      margin-left: 40px;

      max-width: 66%;
      width: 100%;

      @media screen and (max-width: 600px) {
        margin-left: 0;
        max-width: 100%;
      }
    }
    &__right-title {
      text-align: left;
      font-size: 24px;
      line-height: 36px;
      color: var(--text-primary-color-case);
      font-weight: 500;
      margin-bottom: 25px;

      @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 20px;
      }
      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 31px;
      }
    }
    &__right-text {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);
    }
  }
}
</style>
